import React from 'react'
import withClientSuspense from '@helpers/misc/withClientSuspense'
import useWebsiteId from '@hooks/useWebsiteId'
import useWidth from '@hooks/useWidth'
import Container from '@packages/justo-parts/lib/components/Container'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import get from 'lodash/get'

import Content from './Content'
import Title from './Title'

import styles from './styles.module.css'

function Instagram(props) {
  const {title, colsMobile, rowsMobile} = props

  const screenWidth = useWidth()
  const {cols, rows} = screenWidth <= 760 ? {cols: colsMobile, rows: rowsMobile} : props

  const websiteId = useWebsiteId()

  const {website} = useApolloQuery({
    query: gql`
      query ($websiteId: ID, $limit: Float) {
        website(websiteId: $websiteId) {
          _id
          instagramMultimedia(limit: $limit) {
            data {
              caption
              mediaType
              permalink
              username
              thumbnailUrl
              mediaUrl
            }
          }
        }
      }
    `,
    variables: {websiteId, limit: rows * cols}
  })

  const {instagramMultimedia} = website || {}
  const username = get(instagramMultimedia, 'data[0].username')

  if (!username) return null

  return (
    <div className={styles.container}>
      <Container>
        <Title username={username} title={title} />
        <Content cols={cols} posts={instagramMultimedia && instagramMultimedia.data} />
      </Container>
    </div>
  )
}

export default withClientSuspense(Instagram)
