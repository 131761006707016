import {useEffect, useState} from 'react'

import useInterval from './useInterval'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

function getSize(el) {
  if (!el) return
  return el.offsetWidth
}

export default function useComponentSize(ref) {
  const [width, setComponentSize] = useState(getSize(ref.current))

  function handleResize() {
    if (ref && ref.current) {
      const newWidth = getSize(ref.current)
      if (newWidth !== width) {
        if (width && !newWidth) return
        setComponentSize(newWidth)
      }
    }
  }

  useEffect(() => {
    handleResize()
  })

  useIsomorphicLayoutEffect(() => {
    handleResize()
  })

  useInterval(() => {
    if (!width) {
      handleResize()
    }
  }, 100)

  return width || 1
}
