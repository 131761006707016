import React from 'react'
import Image from '@components/Image'
import useComponentWidth from '@hooks/useComponentWidth'
import useRef from '@hooks/useRef'

import styles from './styles.module.css'

export default function Content({posts, cols}) {
  const container = useRef()
  const containerWidth = useComponentWidth(container)
  const width = containerWidth / cols

  if (!posts) return null

  return (
    <div className={styles.posts} ref={container}>
      {posts.map((post, idx) => (
        <div className={styles.post} key={idx} style={{width, height: width}}>
          <a href={post.permalink} target="blank" className={styles.link} style={{height: '100%'}}>
            <Image
              objectFit="cover"
              width={width}
              height={width}
              src={post.mediaType === 'VIDEO' ? post.thumbnailUrl : post.mediaUrl}
              alt={post.caption}
            />
          </a>
        </div>
      ))}
    </div>
  )
}
