import React from 'react'

import styles from './styles.module.css'

export default function Title({username, title}) {
  if (!title) {
    return (
      <div className={styles.title}>
        {title}
        Siguenos en{' '}
        <a target="blank" href={`https://www.instagram.com/${username}`}>
          @{username}
        </a>
      </div>
    )
  }

  const parts = title.split('@')
  return (
    <div className={styles.title}>
      {parts[0]}
      <br />
      <a target="blank" href={`https://www.instagram.com/${username}`}>
        @{username}
      </a>
      {parts[1]}
    </div>
  )
}
