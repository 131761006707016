import ClientSuspense from '@components/ClientSuspense'
import Loading from '@components/SuspenseLoading/Loading'

export default function withClientSuspense(Component, fallback = <Loading />) {
  return function WithClientSuspense(props) {
    return (
      <ClientSuspense fallback={fallback}>
        <Component {...props} />
      </ClientSuspense>
    )
  }
}
