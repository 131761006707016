import {useEffect, useRef} from 'react'

export default function useInterval(callback, time) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    const interval = setInterval(() => callbackRef.current(), time)
    return () => {
      clearInterval(interval)
    }
  }, [time])
}
